import React from 'react';

import './_user-collections-list.scss';
import {collectionUrlCreator} from '../../../constants/Collections';
import {Collections} from '../../../shapes/collections';
import ModalButtonGroup from '../../Reusable/FormElements/ModalButtonGroup/ModalButtonGroup';

import UserCollectionsItem from './UserCollectionsItem';


export interface UserCollectionsListProps {
  listTitle: string;
  userCollectionsResults: Collections[];
  designId: number;
  addOrRemoveDesign: (collectionId: number, designId: number, removeFromCollection: boolean) => void;
  closeModal?: () => void;
  buttonTitleCreateNew?: string;
  buttonTextCreateNew?: string;
  buttonDone?: string;
  showCreateCollectionForm?: () => void;
  isAlphaOrder: boolean;
  isCollectionFetching?: boolean;
}

const UserCollectionsList = ({
  listTitle, userCollectionsResults, designId, addOrRemoveDesign, closeModal, buttonTitleCreateNew,
  buttonTextCreateNew, buttonDone, showCreateCollectionForm, isAlphaOrder, isCollectionFetching
}: UserCollectionsListProps): JSX.Element => {
  const collectionsList = isAlphaOrder ? userCollectionsResults.sort((a, b) => a.name.localeCompare(b.name)) : userCollectionsResults;

  return (
    <React.Fragment>
      <section className='b-user-collections-list'>
        <div className='h3'>{listTitle}</div>
        <ul className='user-collections-list'>
          {collectionsList.map((collectionResult) => (
            <UserCollectionsItem key={collectionResult.id}
              collectionId={collectionResult.id}
              collectionTitle={collectionResult.name}
              collectionUrl={collectionUrlCreator(collectionResult.slug)}
              isPublic={collectionResult.show_collection}
              collectionDesignIds={collectionResult.design_ids}
              designId={designId}
              addOrRemoveDesign={addOrRemoveDesign}
              isCollectionFetching={isCollectionFetching}
            />)
          )
          }
        </ul>
      </section>
      {buttonTitleCreateNew &&
        <ModalButtonGroup
          buttonPrimaryTitle={buttonTitleCreateNew}
          buttonPrimaryText={buttonTextCreateNew}
          buttonSecondaryTitle={buttonDone}
          buttonSecondaryText={buttonDone}
          primaryOnClick={showCreateCollectionForm}
          secondaryOnClick={closeModal}
        />}
    </React.Fragment>
  );
};

export default UserCollectionsList;
