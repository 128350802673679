import leftPad from 'left-pad';
import {COLOR_MAP_ID} from '../constants/Products';
import {METER, YARD} from '../constants/Measurements';
import {
  FABRIC, FAT_QUARTER_METER, FAT_QUARTER_YARD, TEST_SWATCH, YARD_METER, FAT_QUARTER, TEST_SWATCH_METER, TEST_SWATCH_YARD, WALLPAPER, HOME_GOOD
} from '../constants/Codes';
import getNoImageUrl from '../images/getNoImageUrl';
import paths from '../entities/pageSetup/apiHosts/paths';
import {isProductType} from '../shapes/products';
const {s3designThumbnails} = paths;

export const S3_BUCKET_URL_PRODUCTION_PREFIXED = 'https://spoonflower.s3.amazonaws.com';

export const FORMAT_SHOP_THUMB = 'shop_thumb';

export const SMALL_THUMBNAIL_SIZE = 's';
export const MIDDLE_SIZE_THUMBNAIL = 'm';
export const LARGE_SIZE_THUMBNAIL = 'l';
export const SPECIAL_TEA_TOWEL_IMAGE = 'Tea_Towels_special_flat';

const slugToId = (slug) => (
  parseInt(slug.split('-')[0], 10)
);

export const isColorMap = (designId) => {
  // A designId is sometimes a numerical ID, sometimes a slug.
  const identifier = typeof designId === 'string' ? slugToId(designId) : designId;

  return identifier === parseInt(COLOR_MAP_ID, 10);
};

export const designRippleUrl = (fabricImages, fabricSize) => {
  let rippleSize = fabricSize;

  // eslint-disable-next-line default-case
  switch (fabricSize) {
    case YARD:
    case METER:
      rippleSize = YARD_METER;
      break;
    case FAT_QUARTER_YARD:
    case FAT_QUARTER_METER:
      rippleSize = FAT_QUARTER;
      break;
    case TEST_SWATCH_METER:
    case TEST_SWATCH_YARD:
      rippleSize = TEST_SWATCH;
  }

  return fabricImages.ripple[rippleSize];
};

export default class ImageUrls {
  static designImageUrl(s3host, designId, designFileName = '', format = FORMAT_SHOP_THUMB) {
    const paddedId = leftPad(designId, 8, 0);
    const idLeft = paddedId.substr(0, 4);
    const idRight = paddedId.substr(4, 4);
    const fileNameExtensionStripped = designFileName.replace(/\.[^/.]+$/, '');

    return {
      thumbnail: `${s3host}${s3designThumbnails}/${idLeft}/${idRight}/${fileNameExtensionStripped}_${format}.png`,
    };
  }

  static avatarDesignerImageUrl(gardenHost, designerImageId, designerImageFile) {
    const fileNameModified = designerImageFile.replace(/(\.[\w\d_-]+)$/i, '_preview$1');

    return (`${S3_BUCKET_URL_PRODUCTION_PREFIXED}/mugshots/${designerImageId}/${fileNameModified}`);
  }

  static gardenSimpleRippleFQ(gardenHost, designId, designImages) {
    // Getting the first fabric from fabric image object for generic ripple image creation
    const firstFabricImages = designImages[Object.keys(designImages)[0]];
    // check https://spoonflower.atlassian.net/wiki/spaces/SED/pages/180649985/Garden+Images
    const rippleUrl = designRippleUrl(firstFabricImages, FAT_QUARTER);

    return `${gardenHost}/c/${designId}/i/xs/${rippleUrl}`;
  }

  static gardenWallpaperImageUrl(productImages, productTypeCode) {
    return {
      desktop: productImages[productTypeCode]?.desktop,
      mobile: productImages[productTypeCode]?.mobile,
      thumbnail: productImages[productTypeCode]?.icon,
    };
  }

  static gardenProductImageUrl(productImages, productTypeCode, productSize, productStyle) {
    const formula = Object.keys(productImages[productTypeCode][productSize][productStyle])[0];
    const formulaNode = productImages[productTypeCode][productSize][productStyle][formula];

    return {
      desktop: formulaNode.desktop,
      mobile: formulaNode.mobile,
      thumbnail: formulaNode.icon,
    };
  }

  static commentImageUrl(s3host, designId, designFileName, commentId) {
    const paddedId = leftPad(designId, 8, 0);
    const idLeft = paddedId.substr(0, 4);
    const idRight = paddedId.substr(4, 4);
    const fileNameExtensionStripped = designFileName.replace(/\.[^/.]+$/, '');

    return `${s3host}${s3designThumbnails}/${idLeft}/${idRight}/${fileNameExtensionStripped}_comment_${commentId}_thumb.png`;
  }

  static cipheredDesignThumbnail(gardenHost, designId, thumbnailUrl, size = MIDDLE_SIZE_THUMBNAIL, substrateType = FABRIC) {
    return substrateType !== FABRIC ?
      `${gardenHost}/c/${designId}/i/${size}/${thumbnailUrl}` :
      `${gardenHost}/c/${designId}/p/f/${size}/${thumbnailUrl}`;
  }
}

export const setPlaceholderImage = (event, imagesHost) => {
  event.target.src = getNoImageUrl(imagesHost);
};

export const productTypeAbbreviate = (productType) => {
  let productTypeAbbreviated;

  if ([FABRIC, WALLPAPER].includes(productType) && isProductType(productType)) {
    productTypeAbbreviated = productType;
  } else if (productType.startsWith(HOME_GOOD)) {
    productTypeAbbreviated = HOME_GOOD;
  } else {
    productTypeAbbreviated = undefined;
  }

  return productTypeAbbreviated;
};
